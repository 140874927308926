dmx.slideshow.transitions.fade = function(slideshow, options) {
    return new dmx.slideshow.Transition(slideshow, Object.assign({
        duration: 800,

        setup: function() {
            this.slide = document.createElement('div');
            this.slide.style.setProperty('background-size', slideshow.$node.offsetWidth + 'px');
            this.slide.style.setProperty('background-image', 'url("' + slideshow.slides[slideshow.data.index].url + '")');
            this.slide.style.setProperty('position', 'absolute');
            this.slide.style.setProperty('width', '100%');
            this.slide.style.setProperty('height', '100%');
            this.slide.style.setProperty('opacity', 1);
            this.slide.style.setProperty('transition', 'opacity ' + this.options.duration + 'ms ease-in');
            slideshow.effectsContainer.appendChild(this.slide);
            slideshow.showImage(this.index);
        },

        execute: function() {
            this.slide.addEventListener('transitionend', this.finished.bind(this));
            this.slide.style.setProperty('opacity', 0);
            setTimeout(this.finished.bind(this), this.options.duration);
        },

        after: function() {
        }
    }, options));
};
